
                    export const properties = {
                      baseApiUrl: 'https://api.bringup.sandbox.a51.be',
                      authDomain: 'vrt-core.auth.eu-west-1.amazoncognito.com',
                      redirectUrl: 'https://bringup.sandbox.a51.be',
                      region: 'eu-west-1',
                      userPoolId: 'eu-west-1_QBt6fNXsi',
                      userPoolWebClientId: '4s6p4ovg5smhvi93bpt8vrqvmp',
                    };
                    
