import {withRouter} from 'react-router-dom';
import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/en-ie';
import {Auth} from 'aws-amplify';
import axios from 'axios';
import { properties } from '../properties.js';

class Launch extends Component {

  state = {
    actionStarted : false,
    launchSuccessful: false,
    error: null,
    stack : null
  }

  constructor(props) {
    super(props);

      // English but 24h time, all times are in browser's local timezone (should be Europe/Brussels)
      moment.locale('en-ie');

      Auth.currentSession().then(session => {
      console.log('session loaded on first try')
      this.getStackState(session.idToken.jwtToken);
    }).catch(e => {
      console.log('error while retrieving session, try again');
      console.log(e);
      Auth.currentSession().then(session => {
        console.log('session loaded on second try')
        this.getStackState(session.idToken.jwtToken);
      }).catch(e => {
        console.log('error while retrieving session, give up');
        console.log(e);
      });
    });
  }

  getStackState(idToken) {
    var stackName = this.props.match.params.stackName;

    // add the IdToken as a header for api calls (via axios)
    axios.defaults.headers.common['Authorization'] = idToken;

    axios.get(properties.baseApiUrl + '/bringup-api/' + stackName)
      .then(resp => {
        this.setState({stack: resp.data});
      })
      .catch(e => {
        this.setState({
          error: "Failed to find stack with name " + stackName +
            ", verify that the name is correct. Details : " + e
        });
    });
  }

  handleLaunch = (event) => {
    Auth.currentSession().then(session => {
      console.log('session loaded on first try')
      this.launch(session.idToken.jwtToken);
    }).catch(e => {
      console.log('error while retrieving session, try again');
      console.log(e);
      Auth.currentSession().then(session => {
          console.log('session loaded on second try')
          this.launch(session.idToken.jwtToken);
        }).catch(e => {
          console.log('error while retrieving session, give up');
          console.log(e);
        });
    });
  }

  launch(idToken) {
    this.setState({actionStarted : true});
    var stackName = this.props.match.params.stackName;

    // add the IdToken as a header for api calls (via axios)
    axios.defaults.headers.common['Authorization'] = idToken;

    // The structure of this body is hard-linked to the
    // structure in these files:
    // - cfn/backend.py
    // - react-fe/src/Stacks/Stack.js
    // - react-fe/src/Launch/Launch.js
    // - src/manage_resources/manage_resources.py
    var body = {
      action: 'launch',
      name: stackName,
      source: 'Launch.js'
    };
    axios.put(properties.baseApiUrl+'/bringup-api/launch/'+stackName, body)
      .then(resp => {
        this.setState({launchSuccessful : true});
      })
      .catch(e => {
        this.setState({error : "Launch failed : " + e.response.data});
      });
  }

  render() {
    var launchTime = this.state.stack
      && this.state.stack.lastLaunch
      && moment.unix(this.state.stack.lastLaunch / 1000);
    return (
      <div>
        {
          !this.state.actionStarted && 
          <div>
            <h2>Press launch to start up <strong>{this.props.match.params.stackName}</strong></h2>
            {
              launchTime &&
              <p>Last launched: <span title={launchTime.format()}>{launchTime.calendar()}</span></p>
            }
            <button type="button" className="btn btn-default" onClick={this.handleLaunch} title="Launch">
                Launch <span className="glyphicon glyphicon-play-circle"></span>
            </button>
          </div>
        }
        {
          this.state.launchSuccessful && 
          <div className="alert alert-success box-msg" role="alert">
            <strong>Stack launching</strong>, Expected boot-up time :
            {this.state.stack.estimatedMinutesToSpinUp} minutes. 
          </div>
        }
        {
          this.state.error &&
          <div  className="alert alert-danger box-msg" role="alert">
            {this.state.error}
          </div>
        }
      </div>
    );
  }
}

export default withRouter(Launch);

